import { useContext, useEffect, useRef } from "react";
import globalStates from "../../utils/global";
import "./GlobalConveyorScreen.css"
import { IconArrow, ImgBannerAccountingService, ImgBannerBusinessDevelopment, ImgBannerLegalConsultation, ImgBannerPropertyConsultation, ImgHomeScreen1 } from "../../assets";
import { useNavigate } from "react-router-dom";

const GlobalConveyorScreen = () =>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    
    // const businessDetailImagesImg = businessDetailImages && businessDetailImages.screenBannerImg;

    const screenGlobalConveyorRef = useRef(null) as any;
    // console.log({})
    useEffect(()=>{
        const handleScroll =()=>{
            const setScreensOffset = globalContext && globalContext.setScreensOffset;
            const screenGlobalConveyor = screenGlobalConveyorRef.current.getBoundingClientRect();
            const top = screenGlobalConveyor.top;
            const bottom = screenGlobalConveyor.bottom;
            const offset = globalContext && globalContext.windowHeight;
            setScreensOffset((prev:any)=>{return {...prev, 
                isOffsetscreenGlobalConveyor: false, bottom, offset, top
            }});
            if(top < (-1*0.005*offset) ){
                setScreensOffset((prev:any)=>{return {...prev, bottom, offset, top,
                    isOffsetscreenGlobalConveyor: true,
                }});
            };
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, [globalContext])

    const navigate = useNavigate();

    const componentData = {
        background: {
            image: {
                src: "",
                alt: ""
            }
        },
        shield: {

        },
        content: {
            title: "Our Services",
            listServices: [
                {
                    title: "Legal Consultant",
                    desc: "Expertise and excellence in providing legal solutions across diverse practice areas.",
                    image: {
                        src: ImgBannerLegalConsultation,
                        alt: ""
                    },
                    handler: ()=>navigate('/services')
                },
                {
                    title: "Property Consultant",
                    desc: "End-to-end solutions for individuals and business seeking assistance with all aspects of property development and management.",
                    image: {
                        src: ImgBannerPropertyConsultation,
                        alt: ""
                    },
                    handler: ()=>navigate('/services')
                },
                {
                    title: "Business Development",
                    desc: "Empower businesses to navigate challenges and capitalize on opportunities in today's dynamic market landscape.",
                    image: {
                        src: ImgBannerBusinessDevelopment,
                        alt: ""
                    },
                    handler: ()=>navigate('/services')
                },
                {
                    title: "Accounting Service",
                    desc: "Comprehensive financial management solutions tailored to meet the unique needs of businesses across various industries.",
                    image: {
                        src: ImgBannerAccountingService,
                        alt: ""
                    },
                    handler: ()=>navigate('/services')
                }
            ]
        }
    }

    const conveyorRef:any = useRef(null);

    const scrollLeft = () => {
        if (conveyorRef.current) {
            console.log(">>>left", -conveyorRef.current.clientWidth)
            conveyorRef.current.scrollBy({ left: -conveyorRef.current.clientWidth, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (conveyorRef.current) {
            console.log(">>>right", conveyorRef.current.clientWidth)
            conveyorRef.current.scrollBy({ left: conveyorRef.current.clientWidth, behavior: 'smooth' });
        }
    };
    
    return(
        <>
            <div 
            ref={screenGlobalConveyorRef} 
            className={`${windowWidthClass}-global-conveyor-screen wrapper`}>

                <div className="background">
                    <div className="frame">
                        {/* <img
                            alt = {componentData.background.image.alt}
                            src = {componentData.background.image.src}
                        /> */}
                    </div>
                </div>
                <div className="shield">
                
                </div>
                <div className="content display">
                            {
                                windowWidthClass==="w-pc" ?

                               ( <div className="scroll-wrapper display">
                                    {/* <div className="frame" onClick={scrollLeft}>
                                        <img src={IconArrow} alt=""/>
                                    </div>
                                    <div className="frame right">
                                        <img src={IconArrow} alt="" onClick={scrollRight}/>
                                    </div> */}
                                    {/* <button className="scroll-button left" onClick={scrollLeft}>&lt;</button>
                                    <button className="scroll-button right" onClick={scrollRight}>&gt;</button> */}
                                </div>) : <></>
                            }
                    
                   
                    <div className="text-wrapper">
                            <h2>
                                {componentData.content.title}
                            </h2>
                    </div>
                    <div className="scroll-wrapper frame left" onClick={scrollLeft}>
                        <img src={IconArrow} alt=""/>
                    </div>
                    <div className="scroll-wrapper  frame right">
                        <img src={IconArrow} alt="" onClick={scrollRight}/>
                    </div>
                    
                    
                    <div className="wrapper grid" ref={conveyorRef}>
                        
                        {
                            componentData.content.listServices.map((service:any, index:any)=>{
                                const {title, desc, image, handler} = service;
                                return (
                                    <div className="item" >
                                        <div className="card">
                                            <div className="frame">
                                                <img 
                                                    alt = {image.alt}
                                                    src = {image.src}
                                                    onClick={handler}
                                                />
                                            </div>
                                            <div className="text-wrapper">
                                                <h2>{title.toUpperCase()}</h2>
                                                <p>{desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>


                     
                </div>


            </div>
        </>
        
    )
};

export default GlobalConveyorScreen;