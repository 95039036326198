import { useContext, useEffect, useRef } from "react";
import globalStates from "../../utils/global";
import "./ContactDetailScreen.css"
import { IconFacebook, IconInstagram, IconTelegram, IconYoutube } from "../../assets";

const ContactDetailScreen = () =>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    
    // const businessDetailImagesImg = businessDetailImages && businessDetailImages.screenBannerImg;

    const screenContactDetailRef = useRef(null) as any;
    // console.log({})
    useEffect(()=>{
        const handleScroll =()=>{
            const setScreensOffset = globalContext && globalContext.setScreensOffset;
            const screenContactDetail = screenContactDetailRef.current.getBoundingClientRect();
            const top = screenContactDetail.top;
            const bottom = screenContactDetail.bottom;
            const offset = globalContext && globalContext.windowHeight;
            setScreensOffset((prev:any)=>{return {...prev, 
                isOffsetscreenContactDetail: false, bottom, offset, top
            }});
            if(top < (-1*0.005*offset) ){
                setScreensOffset((prev:any)=>{return {...prev, bottom, offset, top,
                    isOffsetscreenContactDetail: true,
                }});
            };
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, [globalContext])

    const componentData = {
        background: {
            image: {
                src: "",
                alt: ""
            }
        },
        shield: {

        },
        content: {
            about: {
                title: "OUR OFFICE",
                desc: [
                    "Jl Kerobokan 12, Kerobokan, Kuta Utara, Kab. Badung, Bali",
                    "Baleka Resort and Spa, Jl Werkudara Legian"
                ]
            }, 
            socials: [
                {
                    title: "@settara_consulting",
                    icon: {
                        src: IconTelegram,
                        alt: ""
                    }
                },
                {
                    title: "@settaraconsulting",
                    icon: {
                        src: IconInstagram,
                        alt: ""
                    }
                },
                // {
                //     title: "Youtube User",
                //     icon: {
                //         src: IconYoutube,
                //         alt: ""
                //     }
                // }
            ]

        }
    }
    
    return(
        <>
            <div 
            ref={screenContactDetailRef} 
            className={`${windowWidthClass}-contact-detail-screen wrapper`}>

                <div className="background">
                    <div className="frame">
                        {/* <img
                            alt = {componentData.background.image.alt}
                            src = {componentData.background.image.src}
                        /> */}
                    </div>
                </div>
                <div className="shield">

                </div>
                <div className="content display">
                   <div className="section about">
                        <h2>{componentData.content.about.title}</h2>
                        <p>{componentData.content.about.desc[0]}</p>
                        <p>{componentData.content.about.desc[1]}</p>
                   </div>
                   <div className="section socials">
                        <h2>FIND US HERE</h2>
                        {
                            componentData.content.socials.map((social:any, index:any)=>{
                                const {title, icon} = social;
                                return (
                                    <div className="object-wrapper">
                                        <div className="frame">
                                             <img 
                                                src={icon.src}
                                                alt={icon.alt}
                                            />
                                        </div>
                                        <p>{title}</p>
                                    </div>
                                )
                            })
                        }
                   </div>
                </div>

            </div>
        </>
        
    )
};

export default ContactDetailScreen;