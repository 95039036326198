import { ImgProfileScreen1 } from "../../assets";
import { AppFooter, AppNavbar } from "../../components";
import { GlobalCardCollage, GlobalHeaderScreen } from "../../containers";

const Profile = () => {

    const data = {
        title: "Settara Team",
        desc: "Brief description about your corporate team value and highlight of other things that showcases your team profiles.",
        image: {
            src: ImgProfileScreen1,
            alt: ""
        }
    }

    return (
        <div className="Profile">
            <AppNavbar/>
            <GlobalHeaderScreen 
                data={data}
            />
            <GlobalCardCollage/>
            <AppFooter/>
        </div>
    )
};

export default Profile;