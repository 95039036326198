import { useContext, useEffect, useRef } from "react";
import globalStates from "../../utils/global";
import "./GlobalHeaderScreen.css"

const GlobalHeaderScreen = ({data}:any) =>{

    const screenTitle = (data && data.title) || "Header"
    const screenDesc = (data && data.desc) || ""
    const screenBackgroundImage = (data && data.image) || {alt: "", src: ""}

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    
    // const businessDetailImagesImg = businessDetailImages && businessDetailImages.screenBannerImg;

    const screenWebsiteRef = useRef(null) as any;
    // console.log({})
    useEffect(()=>{
        const handleScroll =()=>{
            const setScreensOffset = globalContext && globalContext.setScreensOffset;
            const screenWebsite = screenWebsiteRef.current.getBoundingClientRect();
            const top = screenWebsite.top;
            const bottom = screenWebsite.bottom;
            const offset = globalContext && globalContext.windowHeight;
            setScreensOffset((prev:any)=>{return {...prev, 
                isOffsetscreenWebsite: false, bottom, offset, top
            }});
            if(top < (-1*0.005*offset) ){
                setScreensOffset((prev:any)=>{return {...prev, bottom, offset, top,
                    isOffsetscreenWebsite: true,
                }});
            };
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, [globalContext])

    const componentData = {
        background: {
            image: screenBackgroundImage
        },
        shield: {

        },
        content: {
            title: screenTitle,
            desc: screenDesc
        }
    }
    
    return(
        <>
            <div 
            ref={screenWebsiteRef} 
            className={`${windowWidthClass}-global-header-screen wrapper`}>

                <div className="background">
                    <div className="frame">
                        <img
                            alt = {componentData.background.image.alt}
                            src = {componentData.background.image.src}
                        />
                    </div>
                </div>
                <div className="shield">

                </div>
                <div className="content display">
                    <div className="text-wrapper">
                        <h1>{componentData.content.title}</h1>
                        <p>{componentData.content.desc}</p>
                    </div>
                </div>

            </div>
        </>
        
    )
};

export default GlobalHeaderScreen;