import { useContext, useEffect, useRef, useState } from "react";
import globalStates from "../../utils/global";
import "./ServiceSubmenuScreen.css"
import GlobalSubmenuDetail from "../GlobalSubmenuDetail";

const ServiceSubmenuScreen = () =>{

    const [subservice, setSubservice] = useState("LEGAL_CONSULTANT");

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    
    // const businessDetailImagesImg = businessDetailImages && businessDetailImages.screenBannerImg;

    const screenSubmenuServiceRef = useRef(null) as any;
    // console.log({})
    useEffect(()=>{
        const handleScroll =()=>{
            const setScreensOffset = globalContext && globalContext.setScreensOffset;
            const screenSubmenuService = screenSubmenuServiceRef.current.getBoundingClientRect();
            const top = screenSubmenuService.top;
            const bottom = screenSubmenuService.bottom;
            const offset = globalContext && globalContext.windowHeight;
            setScreensOffset((prev:any)=>{return {...prev, 
                isOffsetscreenSubmenuService: false, bottom, offset, top
            }});
            if(top < (-1*0.005*offset) ){
                setScreensOffset((prev:any)=>{return {...prev, bottom, offset, top,
                    isOffsetscreenSubmenuService: true,
                }});
            };
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, [globalContext])

    const componentData = {
        background: {
            image: {
                src: "",
                alt: ""
            }
        },
        shield: {

        },
        content: {
            listSubmenu: [
                {
                    title: "Legal Consultant",
                    code: "LEGAL_CONSULTANT",
                },
                {
                    title: "Property Consultant",
                    code: "PROPERTY_CONSULTANT",
                },
                {
                    title: "Business Development",
                    code: "BUSINESS_DEVELOPMENT",
                },
                {
                    title: "Accounting Service",
                    code: "ACCOUNTING_SERVICE",
                }
            ]
        },
        handlers: {
            navigateSubmenu: (submenu:string)=>{
                const subservice = submenu || "Legal Consultant";
                setSubservice(subservice);
            }
        }
    }

    
    
    return(
        <>
            <div 
            ref={screenSubmenuServiceRef} 
            className={`${windowWidthClass}-service-submenu-screen wrapper`}>

                <div className="background">
                    <div className="frame">
                        {/* <img
                            alt = {componentData.background.image.alt}
                            src = {componentData.background.image.src}
                        /> */}
                    </div>
                </div>
                <div className="shield">

                </div>
                <div className="content display">
                    <div className="menubar">
                            {
                                componentData.content.listSubmenu.map((submenu:any, index:any)=>{
                                    const {title, code} = submenu;

                                    return (
                                        <div 
                                            className="bar"
                                            onClick={()=>componentData.handlers.navigateSubmenu(code)}
                                        >
                                            <p>{title}</p>
                                        </div>
                                    )
                                })
                            }
                    </div>
                    <div className="global-submenu-details">
                       <GlobalSubmenuDetail data={
                            {
                                code: subservice
                            }
                       } />
                    </div>
                </div>

            </div>
        </>
        
    )
};

export default ServiceSubmenuScreen;