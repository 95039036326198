import { useContext } from 'react';
import globalStates from '../../utils/global';
import './AppFooter.css';
import { IconInstagram, IconTelegram, IconWhatsappBlack, WebsiteLogo } from '../../assets';
import { FloatingIcon } from '..';
import { useNavigate } from 'react-router-dom';

const AppFooter = () => {

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;

    const openLinkHandler = (link:any) =>{
        // console.log("link", link)
        window.open(link, '_blank');
    }
    const openWa = (wa:string)=>{
        const waNumber = wa || '628123456789';
        openLinkHandler(`https://wa.me/${waNumber}?text=Hi,%20I'd%20like%20to%20know%20more%20%20about%20this%20product.%20Thank%20you.%20`)
    }
    const openTelegram =  (user:string)=>{
        const path = `https://t.me/${user}`;
        openLinkHandler(path);
    }
    const openInstagram =  (user:string)=>{
        const path = `https://instagram.com/${user}`;
        openLinkHandler(path);
    }
    const navigate = useNavigate()

    const componentData = {
        listAnchors: [
            {
                title: 'Services',
                anchors: [
                    {text: "Legal Consultant", handler: ()=>navigate("/services")},
                    {text: "Property Consultant", handler: ()=>navigate("/services")},
                    {text: "Business Development", handler: ()=>navigate("/services")},
                    {text: "Accounting Service", handler: ()=>navigate("/services")}
                    
                ]
            },
            {
                title: 'Contacts',
                anchors: [
                    // {img: IconTelegram, text: "telegram: @settara_consulting", handler: ()=>openTelegram('settara_consulting')},
                    // {img: IconInstagram, text: "instagram: @settaraconsulting", handler: ()=>openInstagram('settaraconsulting')},
                    // {img: IconWhatsappBlack, text: "instagram: @settaraconsulting", handler: ()=>openWa('6285337851600')},
                    {img: "", text: "🇬🇧 +62 853-3785-1600", handler: ()=>openWa('6285337851600')},
                    {img: "", text:   "🇷🇺 +62 823-4098-1973", handler: ()=>openWa('6282340981973')}
                ]
            },
            {
                title: '',
                anchors: [
                    {img: IconTelegram, text: "telegram: @settara_consulting", handler: ()=>openTelegram('settara_consulting')},
                    {img: IconInstagram, text: "instagram: @settaraconsulting", handler: ()=>openInstagram('settaraconsulting')},
                    {img: IconWhatsappBlack, text: "instagram: @settaraconsulting", handler: ()=>openWa('6285337851600')},
                    // {img: "", text: "🇬🇧 +62 853-3785-1600", handler: ()=>openWa('6285337851600')},
                    // {img: "", text:   "🇷🇺 +62 823-4098-1973", handler: ()=>openWa('6282340981973')}
                ]
            }
        ]
    }
    return (
        <>
        <div className={`${windowWidthClass}-app-footer`}>
            <div className='section anchors'>
                {
                    componentData.listAnchors.map((anchor:any, index:any)=>{
                        const {title, anchors} = anchor;
                        return (
                            <div key={index} className={`anchor-group
                                ${title ? "" : "footer-anchor-group-icons"}
                            `}>
                                <h2>{title}</h2>
                                {
                                    anchors.map((textObject:any, index2:any)=>{
                                        const {text, handler, img} = textObject;
                                        return (
                                            img ? 
                                        
                                        <div className='footer-anchor-frame'>
                                            <img
                                                src={img}
                                                alt={""} 
                                                onClick={handler}
                                            />
                                            {/* <p>{img}</p> */}
                                        </div>
                                        
                                        :

                                        <p key={index2} onClick={handler}>{text}</p>

                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
            <div className='section logo'>
                <div className='frame'>
                    <img
                        src = {WebsiteLogo}
                        alt = ""
                     />
                </div>
            </div>
        </div>
        <FloatingIcon/>
        </>
    )
};

export default AppFooter;