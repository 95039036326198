import { useContext, useEffect, useRef } from "react";
import globalStates from "../../utils/global";
import "./HomeValuesScreen.css"
import { IconScreen2a, IconScreen2b, IconScreen2c } from "../../assets";

const HomeValuesScreen = () =>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    
    // const businessDetailImagesImg = businessDetailImages && businessDetailImages.screenBannerImg;

    const screenHomeValuesRef = useRef(null) as any;
    // console.log({})
    useEffect(()=>{
        const handleScroll =()=>{
            const setScreensOffset = globalContext && globalContext.setScreensOffset;
            const screenHomeValues = screenHomeValuesRef.current.getBoundingClientRect();
            const top = screenHomeValues.top;
            const bottom = screenHomeValues.bottom;
            const offset = globalContext && globalContext.windowHeight;
            setScreensOffset((prev:any)=>{return {...prev, 
                isOffsetscreenHomeValues: false, bottom, offset, top
            }});
            if(top < (-1*0.005*offset) ){
                setScreensOffset((prev:any)=>{return {...prev, bottom, offset, top,
                    isOffsetscreenHomeValues: true,
                }});
            };
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, [globalContext])

    const componentData = {
        background: {
            image: {
                src:"",
                alt: ""
            }
        },
        shield: {

        },
        content: {
            listValues: [
                {
                    value: "Integrity",
                    desc:"We uphold the highest standards of honesty and ethical behavior in all our dealings. Our commitment to integrity ensures that we always act in the best interests of our clients, providing transparent, reliable, and trustworthy services.",
                    image: {
                        src: IconScreen2c,
                        alt: ""
                    }
                },
                {
                    value: "Excellence",
                    desc: "We strive for excellence in everything we do, from delivering top-quality solutions to maintaining exceptional customer service. Our dedication to continuous improvement and innovation drives us to exceed expectations and achieve outstanding results for our clients.",
                    image: {
                        src: IconScreen2b,
                        alt: ""
                    }
                },
                {
                    value: "Professional Team",
                    desc: "Our clients are at the heart of our business. We prioritize understanding their unique needs and goals, tailoring our services to provide personalized and effective solutions. By fostering strong relationships and open communication, we ensure our clients’ success and satisfaction.",
                    image: {
                        src: IconScreen2a,
                        alt: ""
                    }
                }
            ]
        }
    }
    
    return(
        <>
            <div 
            ref={screenHomeValuesRef} 
            className={`${windowWidthClass}-home-values-screen wrapper`}>

                <div className="background">
                    <div className="frame">
                        {/* <img
                            alt = {componentData.background.image.alt}
                            src = {componentData.background.image.src}
                        /> */}
                    </div>
                </div>
                <div className="shield">

                </div>
                <div className="content display">
                    <div className="wrapper grid">
                        {
                            componentData.content.listValues.map((cValue:any, index:any)=>{
                                const {value, desc, image} = cValue;
                                return(
                                    <div className="item">
                                        <div className="card">
                                            <div className="section frame">
                                                <img 
                                                    src={image.src}
                                                    alt={image.alt}
                                                />
                                            </div>
                                            <div className="section text-wrapper">
                                                <h2>
                                                    {value}
                                                </h2>
                                                <p>
                                                    {desc}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>  
                </div>

            </div>
        </>
        
    )
};

export default HomeValuesScreen;