import { createContext } from "react";
import { IconAccountingService, IconBusinessDevelopment, IconLegalConsultant, IconPropertyConsultant, ImgBannerAccountingService, ImgBannerBusinessDevelopment, ImgBannerLegalConsultation, ImgBannerPropertyConsultation } from "../assets";



const webData = {
    subservices: {
        LEGAL_CONSULTANT: {
            title: "Legal Consultant",
            desc: "Expertise and excellence in providing legal solutions across diverse practice areas.",
            image: {
                src: ImgBannerLegalConsultation,
                alt: ""
            },
            listDetails: [
                {
                    title: "Company Establishment",
                    icon: {
                        src: IconLegalConsultant,
                        alt: ""
                    },
                    descs: [
                        "Deed of Establishment",
                        "SK Kemenkumham",
                        "NIB (Nomor Induk Berusaha)",
                        "NPWP (Nomor Pokok Wajib Pajak)",
                        "PKKPR"
                    ]
                },

                {
                    title: "Notarial",
                    icon: {
                        src: IconLegalConsultant,
                        alt: ""
                    },
                    descs: [
                        "Legal Documents Authentication",
                        "Contracts Authentication",
                        "Agreements Authentication",
                        "Apostille"
                    ]
                },{
                    title: "Visa",
                    icon: {
                        src: IconLegalConsultant,
                        alt: ""
                    },
                    descs: [
                        "Single Entry Visa",
                        "Multiple Entry Visa",
                        "Stay Permit",
                        "Immigration Services"
                    ]
                },
                {
                    title: "Agreement",
                    icon: {
                        src: IconLegalConsultant,
                        alt: ""
                    },
                    descs: [
                        "Agreement Drafting",
                        "Contract Drafting"
                    ]
                }
            ]
        },
        PROPERTY_CONSULTANT: {
            title: "Property Consultant",
            desc: "End-to-end solutions for individuals and business seeking assistance with all aspects of property development and management.",
            image: {
                src: ImgBannerPropertyConsultation,
                alt: ""
            },
            listDetails: [
                {
                    title: "Land Acquisition",
                    icon: {
                        src: IconPropertyConsultant,
                        alt: ""
                    },
                    descs: [
                        "Land Searching and Zonation",
                        "Negotiation",
                        "Notarial"
                    ]
                },
                {
                    title: "Planning & Design",
                    icon: {
                        src: IconPropertyConsultant,
                        alt: ""
                    },
                    descs: [
                        "Architecture",
                        "Detail Engineering & Design",
                        "Floor Plan",
                        "Interior Design"
                    ]
                },{
                    title: "Construction",
                    icon: {
                        src: IconPropertyConsultant,
                        alt: ""
                    },
                    descs: [
                        "Structure",
                        "MEP (Mechanical, Engineering, Plumbing)",
                        "Exterior",
                        "Finishing"
                    ]
                },
                {
                    title: "Permitting",
                    icon: {
                        src: IconPropertyConsultant,
                        alt: ""
                    },
                    descs: [
                        "SLF (Sertifikat Laik Fungsi)",
                        "PBG (Persetujuan Bangunan Gedung)",
                        "Local Permit"
                    ]
                }
            ]
        },
        BUSINESS_DEVELOPMENT: {
            title: "Business Development",
            desc: "Empower businesses to navigate challenges and capitalize on opportunities in today's dynamic market landscape.",
            image: {
                src: ImgBannerBusinessDevelopment,
                alt: ""
            },
            listDetails: [
                {
                    title: "Marketing",
                    icon: {
                        src: IconBusinessDevelopment,
                        alt: ""
                    },
                    descs: [
                        "Digital Marketing"
                    ]
                },
                {
                    title: "Business Permitting",
                    icon: {
                        src: IconBusinessDevelopment,
                        alt: ""
                    },
                    descs: [
                        "Customs Permit",
                        "Restaurant Bar Permit",
                        "Hotel Permit",
                        "Alcohol Permit",
                        "Special Permit"
                    ]
                },{
                    title: "Intellectual Property Rights",
                    icon: {
                        src: IconBusinessDevelopment,
                        alt: ""
                    },  
                    descs: [
                        "Brand Registration",
                        "Logo Registration",
                        "Trademark Registration"
                    ]
                },
                {
                    title: "Recruitment",
                    icon: {
                        src: IconBusinessDevelopment,
                        alt: ""
                    },
                    descs: [
                        "Candidate Sourcing",
                        "Screeening and Interviewing",
                        "Offer Negotiation and Onboarding"
                    ]
                }
            ]
        },
        ACCOUNTING_SERVICE: {
            title: "Accounting Service",
            desc: "Comprehensive financial management solutions tailored to meet the unique needs of businesses across various industries.",
            image: {
                src: ImgBannerAccountingService,
                alt: ""
            },
            listDetails: [
               
                {
                    title: "Accounting",
                    icon: {
                        src: IconAccountingService,
                        alt: ""
                    },
                    descs: [
                        "Daily Journal",
                        "Income Statement",
                        "Cashflow Statement",
                        "Balance Sheet",
                        "Statement of Change in Equity"
                    ]
                },{
                    title: "Tax Reporting",
                    icon: {
                        src: IconAccountingService,
                        alt: ""
                    },

                    descs: [
                        "Tax Article 21",
                        "Tax Article 23",
                        "Tax Article 25",
                        "Tax Article 4(2)",
                        "Value Added Tax",
                        "Corporate Yearly Tax"
                    ]
                },
                {
                    title: "Company Report",
                    icon: {
                        src: IconAccountingService,
                        alt: ""
                    },
                    descs: [
                        "Quarterly Report to Indonesia Investment Coordinating Board"
                    ]
                }
            ]
        }
    }
}

// use-context
const GlobalContext = createContext(null);
const globalStates =  {
    globalContext: GlobalContext,
    webData
};

export default globalStates as any;
