import { useContext, useEffect, useRef } from "react";
import globalStates from "../../utils/global";
import "./GlobalCardCollage.css"

const GlobalCardCollage = () =>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    
    // const businessDetailImagesImg = businessDetailImages && businessDetailImages.screenBannerImg;

    const screenGlobalCardCollageRef = useRef(null) as any;
    // console.log({})
    useEffect(()=>{
        const handleScroll =()=>{
            const setScreensOffset = globalContext && globalContext.setScreensOffset;
            const screenGlobalCardCollage = screenGlobalCardCollageRef.current.getBoundingClientRect();
            const top = screenGlobalCardCollage.top;
            const bottom = screenGlobalCardCollage.bottom;
            const offset = globalContext && globalContext.windowHeight;
            setScreensOffset((prev:any)=>{return {...prev, 
                isOffsetscreenGlobalCardCollage: false, bottom, offset, top
            }});
            if(top < (-1*0.005*offset) ){
                setScreensOffset((prev:any)=>{return {...prev, bottom, offset, top,
                    isOffsetscreenGlobalCardCollage: true,
                }});
            };
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, [globalContext])

    const componentData = {
        background: {
            image: {
                src: "",
                alt: ""
            }
        },
        shield: {

        },
        content: {
            listMember: [
                {
                    title: "Lorem Ipsum, S.H.",
                    desc: "Brief description about the team profile."
                },
                {
                    title: "Lorem Ipsum, S.H.",
                    desc: "Brief description about the team profile."
                },
                {
                    title: "Lorem Ipsum, S.H.",
                    desc: "Brief description about the team profile."
                },
                {
                    title: "Lorem Ipsum, S.H.",
                    desc: "Brief description about the team profile."
                },
                {
                    title: "Lorem Ipsum, S.H.",
                    desc: "Brief description about the team profile."
                },
                {
                    title: "Lorem Ipsum, S.H.",
                    desc: "Brief description about the team profile."
                },
                {
                    title: "Lorem Ipsum, S.H.",
                    desc: "Brief description about the team profile."
                },
                {
                    title: "Lorem Ipsum, S.H.",
                    desc: "Brief description about the team profile."
                },
                {
                    title: "Lorem Ipsum, S.H.",
                    desc: "Brief description about the team profile."
                }
            ]
        }
    }
    
    return(
        <>
            <div 
            ref={screenGlobalCardCollageRef} 
            className={`${windowWidthClass}-global-card-collage wrapper`}>

                <div className="background">
                    <div className="frame">
                        {/* <img
                            alt = {componentData.background.image.alt}
                            src = {componentData.background.image.src}
                        /> */}
                    </div>
                </div>
                <div className="shield">

                </div>
                <div className="content display">
                    <div className="wrapper collage">
                        {
                            componentData.content.listMember.map((member:any, index:any)=>{
                                const {title, desc } = member;
                                return (
                                    <div className="item">
                                        <div className="card">
                                            <h2>{title}</h2>
                                            <p>{desc}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
        
    )
};

export default GlobalCardCollage;